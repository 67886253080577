<script>
import { ArrowUpIcon, MapPinIcon,PlusIcon,MoreHorizontalIcon } from "vue-feather-icons";

import Navbar from "@/components/navbar0";
//import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import Testimonial from "@/components/testimonial";
import ResumeHighlight from "./ResumeHighlight";
//import ResumeHighlight from "./ResumeHighlightLess";
import SearchLine from "./SearchLine";
import store from "@/store/store";
/**
 * Index-hosting component
 */
export default {

  components: {
    ResumeHighlight,
    Navbar,
    //Switcher,
    Footer,
    ArrowUpIcon,
    MapPinIcon,
    PlusIcon,
    MoreHorizontalIcon,
    Testimonial,
    SearchLine,
  },
  data() {
    return {

      hover: false,

      additionalParamentersMenu: false,

      search_line_string: '',
      andOrFilter_Options : [
        {id:1,value:'Все слова'},
        {id:2,value:'Любое из слов'},
      ],
      andOrFilter_Selected : {id:1,value:'Все слова'},

      whereToSearchFilter_Options : [
        {id:1,value:'Везде'},
        {id:2,value:'Булевый поиск'},
        {id:3,value:'В должностях'},
        {id:4,value:'В названии компании'},
        {id:5,value:'В образовании'},
        {id:6,value:'В навыках'},
        {id:7,value:'Город'},
        {id:8,value:'В ФИО'},
        {id:9,value:'В email'},
      ],
      whereToSearch_Selected : {id:1,value:'Везде'},

      additionalSearchLines: [1,2],
      advancedSearchSwitch: true,

      orderBy_Options : [
        {id:1,value:'По дате добавления резюме'},
        {id:2,value:'По дате обновления резюме'},
        {id:3,value:'По убыванию зарплат'},
        {id:4,value:'По возрастанию зарплат'},
        {id:5,value:'По убыванию возраста'},
        {id:6,value:'По возрастанию возраста'},
      ],
      orderBy_Selected : {id:1,value:'По дате добавления резюме'},

      resumesPerPage_Options : [
        {id:1,value:'10 резюме'},
        {id:2,value:'20 резюме'},
        {id:3,value:'50 резюме'},
        {id:4,value:'100 резюме'},
      ],
      resumesPerPage_Selected : {id:1,value:'10 резюме'},

      list: [{
        id: 1,
        image: 'images/shop/product/s1.jpg',
        title: 'T-Shirt',
        price: 255,
        qty: 2,
        total: 510
      },

      ],

      input_advanced_search: {
        user_id: 1,
        searchParameters: [],
        searchFlags: {},
      },

      searchLine: '',

      search_parameters: [
        {
        leftParethesis: false,
        field: {name:'Resume headline',id:1,type:'String'},
        relationship:{name:'contains',id:2},
        value:'',
          orButton: false,
          andButton: false,
          rightParethesis:false,
          id:0
        }
        ],

      search_parameters_arr: [{
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      }],

      search_parameters_obj: {
        search_line_string: '',
            andOrFilter_id: 1,
          whereToSearchFilter_id: 1,
      },
      search_parameters_additional: [],

      search_flags: {
        switchEmail: false,
        switchContact: false,

        switchRemote: false,
        switchReadyToMove: false,

        switchHighlighView: false,
        switchResumeView: false,
        switchSaved: false,
      },

      setSQL_ids : {
        ids_arr: [1,2],
        save_search_id: 1,
        user_id: 2,
      },
      totalSearchResults:1,
      savedSearch: {name:'Базовый',id:0,type_id:0},

      resumeIds : [],
      resumes : [],

      total_resume: 358386,

      allVacancies: [
        {
          id: 0,
          name: "Дефолт",
        },
      ],
      activeVacancies: [],
      archiveVacancies: [],
      latestSearches: [],

      input_save_vacancy: {
        type_id: 1,
        user_id: 1,
        vacancy_id:0,
        toDelete: false,
        name: '',
        id: 0,
        status: 0,
      },


      page: {
        active: 1,
        up: 2,
        down: 1,
        max: 3,
        min: 1,
        left_skip: false,
        right_skip: false,
        prev: true,
        next: true,
        skip_number: 3,
      },
      page_arr:[],


    };
  },
  watch: {
    whereToSearch_Selected (val) {
      //console.log('inside watch whereToSearch_Selected: ' + val);
      this.search_parameters_arr[0].whereToSearchFilter_id  = val.id;
      //console.log(this.search_parameters_arr);
    },
    andOrFilter_Selected (val) {
      //console.log('inside watch andOrFilter_Selected: ' + val);
      this.search_parameters_arr[0].andOrFilter_id  = val.id;
      //console.log(this.search_parameters_arr);
    },
    search_line_string(val) {
      //console.log('inside watch search_line_string: ' + val);
      /*this.search_parameters_obj.search_line_string = val;
      let temp_arr = this.search_parameters_arr;
      this.search_parameters_arr = temp_arr.splice(0,1,this.search_parameters_obj);
      */
      this.search_parameters_arr[0].search_line_string = val;
      //console.log(this.search_parameters_arr);
      //console.log(this.search_parameters_obj);
    }
  },
  mounted() {

    this.getAllVacancies();
    this.getLatestSearches();
  },

  methods: {

    nullSearch() {
      this.search_line_string = '';
      this.andOrFilter_Selected = {id: 1, value: 'Все слова'};
      this.whereToSearch_Selected = {id: 1, value: 'Везде'};
      this.search_parameters_arr = [{
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      }];
      this.search_parameters_additional= [];
    },


    addRemoveLine(x) {
      if (x === -1) {
        this.search_parameters_additional.push(this.search_parameters_obj);
        this.search_parameters_arr.push(this.search_parameters_obj);
      } else {
        this.search_parameters_additional.splice(x, 1);
        this.search_parameters_arr.splice(x + 1, 1);
      }
      console.log('inside addRemoveLine:')
      console.log(this.search_parameters_arr);
    },

    updateParameters(e) {
      console.log('inside updateParameters')
      console.log(e);
      this.addRemoveLine(e);
    },
    update_search_parameters_arr(e) {
      console.log('inside update_search_parameters_arr')
      //console.log(e);

      this.search_parameters_arr[e.index+1].search_line_string = e.search_line_string;
      this.search_parameters_arr[e.index+1].andOrFilter_id = e.andOrFilter_id;
      this.search_parameters_arr[e.index+1].whereToSearchFilter_id = e.whereToSearchFilter_id;
      //this.search_parameters_additional = e.splice(0, 1);
      console.log(this.search_parameters_arr);
    },
/*
    updateResume(e) {
      console.log('inside updateResume')
      console.log(e);

    },
    */

    updateResume(e) {
      console.log('inside updateResume')
      console.log(e);

      this.setSQL_ids.ids_arr = [];
      this.setSQL_ids.ids_arr.push(this.resumes[e].id);
      this.setSQL_ids.save_search_id = this.savedSearch.id;
      this.setSQL_ids.user_id = store.state.user_id;
      console.log(this.setSQL_ids)
      //this.resumes.splice(e,e+1);
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/advanced-search/get-resume-byIdList-detail?access_token=' + store.state.token,
        data: this.setSQL_ids,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((res_in) => {
        console.log('inside 2nd axios - get resumes')
        console.log(res_in.data);
        if (res_in.data.length) {

          for (var k = 0; k < res_in.data.length; k++) {
            this.resumes.splice(e, 1, res_in.data[k]);
          }

        }
      });
    },


    searchCandidatesOld(page_selected,resumes_per_page) {
      /*
      this.inputSqlContacts.searchButtonIsPressed = 1;
      this.inputSqlContacts.step = 1
      */
      this.resumeIds = [];
      this.resumes = [];

      console.log(' from ' + resumes_per_page*(page_selected-1) + ' to ' + Math.min(10000, resumes_per_page*page_selected));

      this.input_advanced_search.user_id = store.state.user_id;
      this.input_advanced_search.searchParameters = this.search_parameters_arr;
      this.input_advanced_search.searchFlags = this.search_flags;


      console.log('inside searchCandidates')
      /*
      const loading = this.$vs.loading({
        target: this.$refs.resumesListArea,
        scale: 1,
        opacity: 0.1,
      });
        */
      this.$vs.loading({
        container: 'div-with-loading',
        scale: 1,
        opacity: 0.1,
      });
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/search-candidates/method-mysql-naive/like',
        data: this.input_advanced_search,
      }).then((res) => {
        console.log(res.data);
        if (res.data.status === 'ok') {
          if (res.data.resume_ids.length) {

            this.totalSearchResults = res.data.resume_ids.length;
            this.total_resume = new Intl.NumberFormat().format(this.totalSearchResults);

            this.page.max = Math.ceil(res.data.resume_ids.length/resumes_per_page);

            this.paginationUtil(page_selected);

            /*
            for (var i = 0; i < res.data.resume_ids.length; i++) {
              this.resumeIds.push(res.data.resume_ids[i]);
            }

            console.log(this.resumeIds);
            */

            //this.setSQL_ids.ids_arr = this.resumeIds.slice(0,Math.min(this.totalSearchResults,this.infiniteSearchBy));
            //this.start_index = Math.min(this.totalSearchResults,this.infiniteSearchBy);



            this.setSQL_ids.ids_arr = res.data.resume_ids.slice(resumes_per_page*(page_selected-1), Math.min(res.data.resume_ids.length, resumes_per_page*page_selected));

            this.setSQL_ids.save_search_id = this.savedSearch.id;
            this.setSQL_ids.user_id = store.state.user_id;
            console.log(this.setSQL_ids)
            console.log(this.page);
            console.log(this.page_arr);



            if (this.setSQL_ids.ids_arr.length > 0) {
              this.axios({
                method: 'post',
                url: '' + this.$hostname + '/advanced-search/get-resume-byIdList-detail?access_token=' + store.state.token,
                data: this.setSQL_ids,
                headers: {
                  'Access-Control-Allow-Origin': '*'
                }
              }).then((res_in) => {
                console.log('inside 2nd axios - get resumes')
                console.log(res_in.data);
                if (res_in.data.length) {
                  for (var k = 0; k < res_in.data.length; k++) {
                    this.resumes.push(res_in.data[k]);
                  }
                }
                loading.close();
              });


              this.axios({
                method: 'post',
                url: '' + this.$hostname + '/events/update-was-in-search?access_token=' + this.$store.state.token,
                data: this.setSQL_ids,
                headers: {
                  'Access-Control-Allow-Origin': '*'
                }
              })
                  .then((res_in) => {
                    console.log('inside 2nd axios - events are logged')
                  });


            }
          } else {
            this.$vs.notify({
              time: 4000,
              title: 'Неудача',
              text: 'Ошибка в запросе',
              color: 'danger'
            });
          }
        }
      }).catch((err) => {
        console.error(err);
        //loading.close();
        //this.$router.push('prospect-pool')
      });

      //this.saveLatestSearchParameters();


    },


    searchCandidates() {
      /*
      this.inputSqlContacts.searchButtonIsPressed = 1;
      this.inputSqlContacts.step = 1
      */
      this.resumeIds = [];
      this.resumes = [];

      this.input_advanced_search.user_id = store.state.user_id;
      this.input_advanced_search.searchParameters = this.search_parameters_arr;
      this.input_advanced_search.searchFlags = this.search_flags;

      console.log('inside searchCandidates');
      console.log(this.input_advanced_search);
      /*
      const loading = this.$vs.loading({
        target: this.$refs.resumesListArea,
        scale: 1,
        opacity: 0.1,
      });
      */
      this.$vs.loading({
        container: '#div-with-loading',
        scale: 0.5,
        opacity: 0.1,
      });
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/search-candidates/method-mysql-naive/like?access_token='+ store.state.token,
        data: this.input_advanced_search,
      }).then((res) => {
//        console.log(res.data);
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (res.data.status === 'ok') {
            //this.$router.push({path:'/search-candidates/'+res.data.hash+'/page/'+1});
          let route = this.$router.resolve({path: '/search-candidates/'+res.data.hash+'/page/1'});
          window.open(route.href, '_self');
        }

      }).catch((err) => {
        console.error(err);
        //loading.close();
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        //this.$router.push('prospect-pool')
      });

      //this.saveLatestSearchParameters();


    },


    searchCandidatesFullText() {
      /*
      this.inputSqlContacts.searchButtonIsPressed = 1;
      this.inputSqlContacts.step = 1
      */
      this.resumeIds = [];
      this.resumes = [];



      this.input_advanced_search.user_id = store.state.user_id;
      this.input_advanced_search.searchParameters = this.search_parameters_arr;
      this.input_advanced_search.searchFlags = this.search_flags;


      console.log('inside searchCandidates')
      /*
      const loading = this.$vs.loading({
        target: this.$refs.resumesListArea,
        scale: 1,
        opacity: 0.1,
      });
        */
      this.$vs.loading({
        container: '#div-with-loading',
        scale: 0.5,
        opacity: 0.1,
      });
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/search-candidates/method-mysql-fulltext/like?access_token=' + store.state.token,
        data: this.input_advanced_search,
      }).then((res) => {
//        console.log(res.data);
        if (res.data.status === 'ok') {
          //this.$router.push({path:'/search-candidates/'+res.data.hash+"/page/"+1});
          let route = this.$router.resolve({path: '/search-candidates/'+res.data.hash+'/page/1'});
          window.open(route.href, '_self');
        }
      }).catch((err) => {
        console.error(err);
        loading.close();
        //this.$router.push('prospect-pool')
      });

      //this.saveLatestSearchParameters();


    },



    getAllVacancies() {

      this.input_save_vacancy.user_id = store.state.user_id;

      var url = '' + this.$hostname + '/prod/vacancy/get-list?access_token=' + store.state.token;
      console.log('inside get all jobs ' + this.input_save_vacancy + '  ' + url)

      this.axios({
        method: 'post',
        url: url,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.allVacancies = res.data.arr;
        this.activeVacancies = this.allVacancies.filter(x => x.status === 'Активная');
        this.archiveVacancies = this.allVacancies.filter(x => x.status === 'Архивная');

        console.log(this.allVacancies);
        console.log(this.activeVacancies);
        /*
          if (this.allVacancies.length) {
            this.savedSearch = this.allVacancies[0];
          }
        */
      });

    },



    getLatestSearches() {

      this.input_save_vacancy.user_id = store.state.user_id;


      console.log('inside get all getLatestSearches ')

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/search-candidates/method-mysql-naive/get-all-searches?access_token=' + store.state.token,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.latestSearches = res.data.latestSearches;
      });

    },

    removeSearch(ix) {

      this.input_save_vacancy.user_id = store.state.user_id;
      this.input_save_vacancy.name = this.latestSearches[ix].hash

      console.log('inside get all removeSearch ')

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/search-candidates/method-mysql-naive/update-searches?access_token=' + store.state.token,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.getLatestSearches();
      });

    },



    paginationUtil(x) {
      this.page.active = parseInt(x);
      this.page.up = Math.min(this.page.active+1, this.page.max);
      this.page.down = Math.max(this.page.active-1, this.page.min);
      if (this.page.active>this.page.min) {
        this.page_arr.push({page: '' + this.page.min, active: 'notactive'});
      }
      if (this.page.active === this.page.min) {
        this.page.prev = false;
      }
      if (this.page.active === this.page.max) {
        this.page.next = false;
      }

      if (this.page.active-this.page.min>=this.page.skip_number) {
        this.page.left_skip = true;
        this.page_arr.push({page:'...',active:'notactive'});
        this.page_arr.push({page:''+this.page.down,active:'notactive'});
        if (this.page.active<this.page.max) {
          this.page_arr.push({page: '' + this.page.active, active: 'active'});
        }
      } else {

        for (var i=2; i<this.page.active; i++) {
          this.page_arr.push({page:''+i,active:'notactive'});
        }
        if (this.page.active<this.page.max) {
          this.page_arr.push({page:''+this.page.active,active:'active'});
        }

      }
      if (this.page.max-this.page.active>=this.page.skip_number) {
        this.page.right_skip = true;
        this.page_arr.push({page:''+this.page.up,active:'notactive'});
        this.page_arr.push({page:'...',active:'notactive'});
        this.page_arr.push({page:''+this.page.max,active:'notactive'});

      } else {
        for (var i = this.page.up; i <= this.page.max; i++) {

          if (this.page.max===i & this.page.active === this.page.max) {
            this.page_arr.push({page: '' + i, active: 'active'});
          } else {
            this.page_arr.push({page: '' + i, active: 'notactive'});
          }
        }

      }
      console.log(this.page_arr);
      console.log(this.page);
    },



    PaginationStep(x){

      if (x===-1) {
        let route = this.$router.resolve({path: '/search-candidates/'+this.$route.params.search_hash+'/page/'+this.page.down});
        window.open(route.href, '_self');
      } else if (x===-2) {
        let route = this.$router.resolve({path: '/search-candidates/'+this.$route.params.search_hash+'/page/'+this.page.up});
        window.open(route.href, '_self');
      } else {
        let route = this.$router.resolve({path: '/search-candidates/'+this.$route.params.search_hash+'/page/'+x});
        window.open(route.href, '_self');
      }

    },




  },


};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />


    <!-- Search Start -->
    <section class="section-two bg-light">
      <div class="container mt-5 vs-con-loading__container" id="div-with-loading">
        <div class="row justify-content-center">

          <div class="col-lg-12">

            <div class="d-flex justify-content-center">
              <h3>В базе всего {{ new Intl.NumberFormat().format(total_resume).replace(',', ' ') }} резюме </h3>


            </div>

            <form class="p-4 shadow bg-white rounded">


              <div class="d-flex justify-content-between">
              <h4 class="mb-3">Поиск специалиста</h4>
                <div>
                  <a class="btn btn-outline-light btn-sm"
                     v-b-modal.HistoricSearches
                     data-toggle="modal"
                     data-target="#HistoricSearches"> Предыдущие поиски </a>
                  <b-modal id="HistoricSearches" title="Предыдущие поиски" centered>
                    <div class="table-responsive bg-white shadow">
                      <table class="table table-center table-padding mb-0">
                        <thead>
                        <tr>

                          <th class="text-left py-3" >Ключевые слова</th>
                          <th class="text-left py-3" >Дата</th>

                          <th class="text-left py-3" ></th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="(item, ix) of latestSearches" :key="ix">
                          <td class="text-left" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                              @click="$router.push({path:'/search-candidates/'+item.hash+'/page/1'})">
                            {{item.name}}</td>
                          <td class="text-left"> {{item.date}}</td>
                          <td class="h6"><a @click="removeSearch(ix)"
                                            @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                                            class="text-danger">X</a></td>
                        </tr>

                        </tbody>
                      </table>
                    </div>

                  </b-modal>
                  <!--<a href="#" class="btn btn-outline-light btn-sm ml-1"> Сохранить </a>-->
                  <a @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                     @click="nullSearch" class="btn btn-outline-light btn-sm ml-1"> Сбросить </a></div>
              </div>
              <div class="row">
                <div class="col-10">

                  <!-- <div class="input-group form-group mb-0">

                       <input
                         name="name"
                         id="name"
                         type="text"
                         class="form-control rounded-left"
                         placeholder="Введите ключевые слова :"
                         v-model="search_parameters[0].value"
                       />

                     <div class="col-2" id="button-addon4">-->
                    <div class="input-group-append form-group">





                      <input
                          name="name"
                          id="name"
                          type="text"
                          class="form-control rounded-left col-6"
                          placeholder="Введите ключевые слова :"
                          v-model="search_line_string"
                      />

                      <select class="form-control custom-select rounded-0" v-model="whereToSearch_Selected">
                        <option v-bind:value="x" v-for="x in whereToSearchFilter_Options">{{x.value}}</option>
                      </select>
                      <select class="form-control custom-select rounded-0" v-model="andOrFilter_Selected">
                        <option v-bind:value="x" v-for="x in andOrFilter_Options">{{x.value}}</option>
                      </select>



                      <!--
                      <select class="form-control rounded-0">
                        <option>Разработчик</option>
                        <option>Дизайнер</option>
                        <option>Продакт</option>
                        <option>Продавец</option>
                        <option>Аналитик</option>
                        <option>Маркетолог</option>
                      </select>
                      <select class="form-control rounded-0">
                        <option>Разработчик</option>
                        <option>Дизайнер</option>
                        <option>Продакт</option>
                        <option>Продавец</option>
                        <option>Аналитик</option>
                        <option>Маркетолог</option>
                      </select>
                      -->
                        <!--
                        <input
                          type="submit"
                          id="search"
                          name="search"
                          class="searchbtn btn btn-primary btn-block"
                          value="Поиск"
                          @click="searchCandidates"
                        />
                        -->
                      <input
                          class="searchbtn btn btn-primary btn-block"
                          value="Поиск"
                          @click="searchCandidatesFullText"
                      />
                      </div>
                  <SearchLine v-for="(line,ix) in search_parameters_additional"
                          :index="ix" @index="updateParameters"
                          :search_parameters_arr="search_parameters_arr"
                          @search_parameters_arr="update_search_parameters_arr"
                          />
                </div>
                      <div class="col-2">
                      <a class="btn btn-icon btn-md btn-outline-info btn-pills mr-1" @click="addRemoveLine(-1)"><plus-icon class="fea icon-md"></plus-icon></a>
                      <a class="btn btn-icon btn-md btn-outline-info btn-pills" @click="additionalParamentersMenu=!additionalParamentersMenu">
                        <more-horizontal-icon class="fea icon-md"></more-horizontal-icon></a>

                      </div>
                   <!-- </div> -->
                  </div>

              <div class="widget border-bottom" v-if="additionalParamentersMenu">
                <h4 class="widget-title">Дополнительные параметры поиска</h4>
                <div class="row">
                  <div class="col-2 ">
                  <label >Резюме содержит: </label>
                    <label class="mt-2">Кандидаты: </label>
                    <label class="mt-2">Не показывать: </label>
                  </div>
                  <div class="col-8">

                    <div class="d-flex justify-content-start align-items-center">
                      <div class="form-group mr-4">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="switchEmail" v-model="search_flags.switchEmail">
                          <label class="custom-control-label" for="switchEmail">Email</label>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="switchContact" v-model="search_flags.switchContact">
                          <label class="custom-control-label" for="switchContact"> Телефон или Telegram </label>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-start align-items-center">
                      <div class="form-group mr-4">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="switchRemote" v-model="search_flags.switchRemote">
                          <label class="custom-control-label" for="switchRemote">Готовы работать удаленно</label>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="switchReadyToMove" v-model="search_flags.switchReadyToMove">
                          <label class="custom-control-label" for="switchReadyToMove">Готовы к переезду</label>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-start align-items-center">
                      <div class="form-group mr-4">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="switchHighlighView" v-model="search_flags.switchHighlighView">
                          <label class="custom-control-label" for="switchHighlighView">Резюме попали в поиск</label>
                        </div>
                      </div>

                      <div class="form-group mr-4">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="switchResumeView" v-model="search_flags.switchResumeView">
                          <label class="custom-control-label" for="switchResumeView">Открытые резюме</label>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="switchSaved" v-model="search_flags.switchSaved">
                          <label class="custom-control-label" for="switchSaved">Сохраненные резюме</label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
                  <!--
                  <vs-switch class="item ml-3" v-model="search_flags.switchEmail"/><label class="item m-1">Email</label>
                  <vs-switch class="item ml-3" v-model="search_flags.switchContact"/><label class="item m-1">Контакты</label>
                  <vs-switch class="item ml-3" v-model="search_flags.switchRemote"/><label class="item m-1">Удаленка</label>
                  <vs-switch class="item ml-3" v-model="search_flags.switchReadyToMove"/><label class="item m-1">Переезд</label>

                </div>


                <div class="col" >
                  <label class="item m-2"><b>Не показывать:</b> </label>
                  <vs-switch class="item ml-3" v-model="search_flags.switchHighlighView"/><label class="item m-1">Ранее попали в поиск</label>
                  <vs-switch class="item ml-3" v-model="search_flags.switchResumeView"/><label class="item m-1">Открыты резюме</label>
                  <vs-switch class="item ml-3" v-model="search_flags.switchSaved"/><label class="item m-1">Сохранены</label>
                </div>

              </div>
              -->

              <div class="row">
                <div class="col-lg-2 col-md-4 col-6 mt-4">
                  <a href="https://skillrank.ru/search-candidates/98f0f0e2a8cc6c209283e21c6d4a7ae6/page/1" class="text-dark">
                    <div class="rounded shadow bg-white p-1 text-center">
                      <h6 class="mb-0">
                        Разработчик <br /><span class="text-primary">Java</span> Москва
                      </h6>
                    </div>
                  </a>
                </div>

                <div class="col-lg-2 col-md-4 col-6 mt-4">
                  <a href="https://skillrank.ru/search-candidates/ff84b8008ce3888ce41bed162592005a/page/1" class="text-dark">
                    <div class="rounded shadow bg-white p-1 text-center">
                      <h6 class="mb-0">
                        Дизайнер <br /><span class="text-primary">Figma</span> Спб
                      </h6>
                    </div>
                  </a>
                </div>
                <!--end col-->

                <div class="col-lg-2 col-md-4 col-6 mt-4">
                  <a href="https://skillrank.ru/search-candidates/69cbb018ca746a2a2ae28041bf90cf9e/page/1" class="text-dark">
                    <div class="rounded shadow bg-white p-1 text-center">
                      <h6 class="mb-0">
                        Продакт <br /><span class="text-primary">GameDev</span>
                      </h6>
                    </div>
                  </a>
                </div>
                <!--end col-->

                <div class="col-lg-2 col-md-4 col-6 mt-4">
                  <a href="https://skillrank.ru/search-candidates/afac2cd207533257480a4883ef4b42a1/page/1" class="text-dark">
                    <div class="rounded shadow bg-white p-1 text-center">
                      <h6 class="mb-0">
                        Продавец <br /><span class="text-primary">SaaS</span>
                      </h6>
                    </div>
                  </a>
                </div>
                <!--end col-->

                <div class="col-lg-2 col-md-4 col-6 mt-4">
                  <a href="https://skillrank.ru/search-candidates/55ce63970ae95eab2d0288c09203d8b6/page/1" class="text-dark">
                    <div class="rounded shadow bg-white p-1 text-center">
                      <h6 class="mb-0">
                        Маркетолог<br /><span class="text-primary">SMM</span>
                      </h6>
                    </div>
                  </a>
                </div>
                <!--end col-->

                <div class="col-lg-2 col-md-4 col-6 mt-4">
                  <a href="https://skillrank.ru/search-candidates/6d8b4372588ca21e3065c82ee3c75606/page/1" class="text-dark">
                    <div class="rounded shadow bg-white p-1 text-center">
                      <h6 class="mb-0">
                        Аналитик <br /><span class="text-primary">DS</span> в банк
                      </h6>
                    </div>
                  </a>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
            <!--end form-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>




    <!-- Job List Start -->
    <!--<section class="section">
      <div class="container"> -->
      <!--<div class="row m-4">
        <div class="col-lg-3 col-md-6 col-12">
          <input
              class="searchbtn btn btn-primary btn-block"
              value="Создать вакансию"
              @click="$router.push({path:'/new-job-edit'})"
          />
        </div>
        <div class="col-lg-8 col-md-6 col-12">
           <h4>В базе всего {{ new Intl.NumberFormat().format(total_resume).replace(',', ' ') }} резюме </h4>
          </div>
      </div>


    </section> -->







    <Footer />
    <!--
    <Switcher />
    -->

    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
